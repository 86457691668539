
interface IInfoBoxProps {
    title: string;
    children: React.ReactNode;
    }

export default function WarningBox(props: IInfoBoxProps) {
  return (
    <div className="warning-box">
      <h3 className="info-box-title">{props.title}</h3>
      {props.children}
    </div>
  );
};


