// src/components/MeetOurPractitioner.tsx
import React from 'react';
import './practitioner.css'; // Make sure to create this CSS file
import practitionerPhoto from '../images/practitioner.png'; // Update the path as necessary

const MeetOurPractitioner = () => {
    return (
        <section className="practitioner">
            <h2>Meet Our Practitioner</h2>
            <div className="practitioner-details">
                <img src={practitionerPhoto} alt="Herdip Earnshaw" className="practitioner-photo" />
                <div className="practitioner-info">
                    <h3>Herdip Earnshaw</h3>
                    <p>I am qualified in therapeutic, healing therapies and beauty treatments from the NCC (National College Complementary Therapies). My treatments take place in a beautiful, newly equipped treatment room that is specially designed to give you the complete calming experience that you desire throughout your treatments.</p>
                </div>
            </div>
        </section>
    );
};

export default MeetOurPractitioner;
