// src/components/Header.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css'; // Make sure this CSS file exists and is updated for the drawer
import footerImage from '../images/drawerfooter.jpg'; // Make sure this image exists and is updated for the drawer
import Divider from './divider';

const Header = () => {
    // State to manage if the menu is open or not
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Function to toggle the menu state
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="burger-menu" onClick={toggleMenu}>
                <div className="burger-bar"></div>
                <div className="burger-bar"></div>
                <div className="burger-bar"></div>
            </div>
            <div className="site-name">
                <Link to="/">Tranquillity Spa</Link>
            </div>
            {/* Drawer menu */}
            <nav className={`drawer-menu ${isMenuOpen ? 'open' : ''}`}>
                <button className="close-button" onClick={toggleMenu}>×</button>
                <div className="menu-links">
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                    <Divider />
                    <Link to="/treatments" onClick={toggleMenu}>Our Treatments</Link>
                    <Link to="/special-packages" onClick={toggleMenu}>Special Packages</Link>
                    <Link to="/body-contouring" onClick={toggleMenu}>Body Contouring</Link>
                    <Divider />
                    <Link to="/massage" onClick={toggleMenu}>Massage</Link>
                    <Link to="/reiki" onClick={toggleMenu}>Reiki</Link>
                    <Link to="/reflexology" onClick={toggleMenu}>Reflexology</Link>
                    <Link to="/body-contouring-page" onClick={toggleMenu}>About Body Contouring</Link>
                    <Divider />
                    <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
                </div>
                <div className="drawer-footer">
                    <Divider />
                    <img src={footerImage} alt="Tranquillity Spa" className="drawer-image" />
                    <p>A place where you can switch off and escape the stresses of the outside world and everyday life.</p>
                </div>
            </nav>
        </header>
    );
};

export default Header;
