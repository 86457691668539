import './reikipage.css'; // Make sure to create this CSS file
import reikiImage from '../images/Reiki.jpg'; // Update with the correct path

const ReikiPage = () => {
  return (
    <div className="reiki-page-container">
        <h1>Reiki</h1>
        <div className='intro-section'>
            <p>Receiving Reiki treatment is a unique experience. </p>
            <p>Reiki is a powerful hands-on healing system originating in Japan. It is received fully clothed whilst lying on a therapist's couch. The treatment is deeply relaxing and will last about an hour.</p>
            <p>The therapist acts as a channel for the universal healing energy chi to pass through our hands to your body, encouraging the body to heal itself. You may feel a warm or cool sensation or you may feel nothing at all.</p>
        </div>
        <div className="reiki-image-container">
            <img src={reikiImage} alt="Reiki healing" />
        </div>
        <div className="reiki-experience-section">
            <h2>The Reiki Experience</h2>
            <p>The Reiki technique is a unique approach to integrating the healing process between mind, body and spirit. It is highly effective in combating the stresses of busy modern life.</p>
            <div className="benefits-section">
                <p>Reiki treatment is effective in the following areas:</p>
                <ul className="benefits-list">
                    <li>Helping to boost the immune system</li>
                    <li>Increasing the effectiveness of conventional medical therapies</li>
                    <li>Increasing and maintaining your physical, emotional and mental strength</li>
                    <li>Encouraging self-confidence</li>
                    <li>Releasing past traumas</li>
                    <li>Improving circulation</li>
                    <li>Releasing blockages</li>
                    <li>Speed up the healing of broken bones</li>
                    <li>Arthritis relief</li>
                    <li>Stress relief</li>
                </ul>
            </div>
        </div>
        <div className="closing-remark-section">
            <p>As for most things in life, Reiki needs to be experienced to be fully appreciated. Many people have found that they have received greater benefits than they initially expected. It may also be used in palliative care situations, invaluable to relieve pain.</p>
        </div>
    </div>
  );
};

export default ReikiPage;
