// src/components/Testimonials.tsx
import React from 'react';
import './testimonials.css'; // Make sure to create this CSS file

const testimonials = [
  {
    quote: "Herdip makes you feel so special from the moment you walk through her gate. The Ultra Sonic Facial is just amazing with the result it gives making me look and feel years younger.",
    author: "Helen W,"
  },
  {
    quote: "I came today for reflexology and a therapeutic massage and it was amazing. I felt like I was floating by the time Herdip had finished working her magic.",
    author: "Stacey A,"
  },
  {
    quote: "I had Reiki which I was sceptical about. However, after the treatment, I really did feel the benefits. This is an amazing experience and environment this treatment really does not get the recognition it deserves.",
    author: "Damian E,"
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials">
      <h2>What our clients say</h2>
      <div className="testimonial-wrapper">
        {testimonials.map((testimonial, index) => (
          <blockquote key={index} className="testimonial">
            <p>{testimonial.quote}</p>
            <footer>{testimonial.author}</footer>
          </blockquote>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
