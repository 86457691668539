// src/components/HealthValues.tsx
import React from 'react';
import './healthvalues.css'; // Make sure to create this CSS file

const HealthValues = () => {
  const healthTopics = [
    {
      title: 'Advanced Reflexology',
      description: 'Reflexology is a therapy which aims to awaken the chakras and encourage healing, relieve stress, tension, and improve general health and well-being.',
      icon: '👣', // You can replace this with an actual icon image or font icon
    },
    {
      title: 'Facials',
      description: 'After just one treatment the combination of the healing properties of our skincare range and unique massage techniques will improve elasticity and tone, boost circulation, and eliminate puffiness. Defining facial contours and features as well as plumping the skin, diminishing fine lines and wrinkles to reveal an even, radiant and polished, youthful complexion.',
      icon: '💆‍♀️', // You can replace this with an actual icon image or font icon
    },
    {
      title: 'Hopi Ear Candles',
      description: 'Hopi Ear Candles are particularly good for clearing sinuses, gaining relief from hay fever, tinnitus, hearing difficulties and problems with balance.',
      icon: '🕯', // You can replace this with an actual icon image or font icon
    },
  ];

  return (
    <section className="health-values">
      <h2>Health Values</h2>
      <div className="health-values-content">
        {healthTopics.map((topic, index) => (
          <div key={index} className="health-value">
            <span className="icon">{topic.icon}</span>
            <h3>{topic.title}</h3>
            <p>{topic.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HealthValues;
