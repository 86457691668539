import './bodycontouringpage.css'; 
import beforeafter1 from '../images/beforeafter1.jpg'; 
import beforeafter2 from '../images/beforeafter2.jpg'; 
import InfoBox from '../components/infobox';
import WarningBox from '../components/warningbox';

// Import more images as needed

const BodyContouringPage = () => {
  return (
    <div className="body-contouring-page-container">
        <h1>Body Contouring</h1>
        <h2>Body Contouring and Inch Loss (Non-Surgical Fat Reduction and Skin Tightening)</h2>
        <p>Non-surgical fat reduction treatment using multiple different procedures designed to dissolve stubborn fat, tighten skin, and rid the body of cellulite and stretch marks, whilst shaping different areas of the body.</p>
        <p>Laser Lipolysis, Radio Frequency Therapy, Ultrasound Cavitation, and Vacuum Therapy can be used as an individual or as a combination of treatments to contour the body and aid inch loss when used in conjunction with healthy eating and exercise.</p>
        <h2>How does it work?</h2>
        <p>Body Contouring is bespoke to each client. The fat cells are broken down and liquified. The lymphatic system then drains the toxins and fat cells out of the body through the kidneys and liver. It is essential that clients drink lots of water to assist this process. Once treatments start the loss of fat starts right away, and clients will continue losing fat for up to 3 days after their session but can go on as long as 6 months. With consistent weekly sessions, clients will see big changes in 3 weeks but maximum results will be seen 3 – 6 months after, providing treatments are kept up with.</p>
        <div className="info-box">
            <p>Clients are recommended to do weekly treatments, 6 – 12 treatments before needing to take a 4 week break to allow the body to recover.</p>
        </div>
        <p>Laser Lipolysis – An innovative treatment using high-intensity light to break down excess fat in the shallow layers of the skin. This treatment noticeably shapes the body whilst reducing fat and cellulite. Can be used on all areas of the body.</p>
        <p>Radio Frequency – An aesthetic technique using radio frequency (RF) energy to heat the skin and stimulate collagen production in order to reduce the appearance of fine lines, and stretch marks and tighten the skin.</p>
        <p>Ultrasonic Cavitation – Using low-frequency sound waves to heat and vibrate the layer of fat cells below the skin’s surface, causing the fat cells to liquefy.</p>
        <div className="image-section">
            <figure>
                <img src={beforeafter1} alt="Before and after treatment" className="before-after-image" />
                <figcaption>
                Body Contouring/Slim Waist combination treatment to achieve the best inch loss results, session to include Laser Lipolysis, Ultrasonic Cavitation, Radio Frequency, and Vacuum Therapy.
                </figcaption>
            </figure>
        </div>
        <InfoBox title="Vacuum Massage">
            <p>Reduces cellulite by lifting the skin with suction and aiding the detoxification process within the body. Incorporates Radio Frequency and Cavitation. It can be performed on all body parts to reduce cellulite.</p>
        </InfoBox>
        <InfoBox title="Radio Frequency Facials">
            <p>Perfect for giving clients that chiseled chin, or reducing puffiness and giving cheeks a more contoured look.</p>
        </InfoBox>
        <div className="image-section">
            <figure>
                <img src={beforeafter2} alt="Before and after treatment" className="before-after-image" />
                <figcaption>
                Chiseled jawline treatment using laser lipolysis, and radio frequency facial to firm, lift and rejuvenate the face.
                </figcaption>
            </figure>
        </div>
        <WarningBox title="">
            <p>Not suitable for clients with cancer, uncontrolled diabetes, uncontrolled high BP, heart problems, kidney or liver problems, epilepsy, thyroid disease, pregnant or breastfeeding ladies</p>
        </WarningBox>
    </div>
  );
};

export default BodyContouringPage;
