import './pricelist.css'; 

type PriceInfo = {
  name: string;
  price: number;
  description: string;
};

interface IPriceListPageProps {
  prices: PriceInfo[];
  footerText?: string;
  title: string
}

export default function PriceListPage(props: IPriceListPageProps) {
  return (
    <div className="price-list-container">
      <h1>{props.title}</h1>
      <div className="cards-container">
        {props.prices.map((price, index) => (
          <div key={index} className="price-card">
            <h3>{price.name} (£{price.price})</h3>
            <p className="price-description">{price.description}</p>
          </div>
        ))}
      </div>
      {props.footerText && (
        <div className="footer-text">
          <p>{props.footerText}</p>
        </div>
      )}
    </div>
  );
};


