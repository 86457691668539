import './reflexologypage.css'; // Make sure to create this CSS file
import reflexologyImage from '../images/reflexology.jpg'; // Update with the correct path

const ReflexologyPage = () => {
  return (
    <div className="reflexology-page-container">
      <div className="reflexology-content">
        <h1>Reflexology</h1>
        <p>Reflexology is a therapeutic, healing holistic treatment which involves applying pressure to, and massaging certain areas of the feet, hands, face and ears to awaken the chakras. The aim is to encourage healing and to relieve stress and tension. It improves general health and well-being.</p>
        <h2>Benefits of advanced reflexology</h2>
        <ul className="benefits-list">
            <li>Promotes health and wellbeing</li>
            <li>Reduces stress and anxiety</li>
            <li>Increases serotonin and endorphins to improve mood</li>
            <li>Provides relaxation and improves quality of sleep</li>
            <li>Increases energy and removes energy blocks</li>
            <li>Stimulates circulation</li>
            <li>Improves lymphatic flow</li>
            <li>Boosts immune system</li>
            <li>Speeds up healing of wounds</li>
            <li>Aids post-operative recovery</li>
            <li>Reduces aches and pains</li>
            <li>Improves spinal flexibility</li>
            <li>Provides relief from rheumatoid and arthritic pain</li>
            <li>Balances hormones</li>
            <li>Reduces symptoms of PMS</li>
            <li>Reduces severity of migraines, headaches and sinusitis</li>
            <li>Improves metabolism and provides digestive calmness</li>
            <li>Preventative healthcare</li>
        </ul>
        <p>We work holistically and aim to work alongside allopathic healthcare to promote better health and restore balance for our clients. As well trained reflexologists, we do not claim to cure, diagnose or prescribe. Reflexology is a very individual treatment, which is tailored to you as a whole person, taking into account both physical and non-physical factors that might be affecting your well-being.</p>
      </div>
      <div className="reflexology-image-container">
        <img src={reflexologyImage} alt="Reflexology" />
      </div>
    </div>
  );
};

export default ReflexologyPage;
