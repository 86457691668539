import { useState } from "react";
import "./contactpage.css";


export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    humanCheck: ""
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formData.humanCheck !== "human") {
      alert("Please confirm that you are a human!");
      return;
    }

    // Send the email if the human check passes
    const response = await fetch("/sendmail.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    });

    const result = await response.json();
    console.log(result);

    // Close the form or reset the state after sending the email
    setFormData({
      name: "",
      email: "",
      message: "",
      humanCheck: ""
    });
  };

  return (
    <main>
      <header>
        <h1>Contact Us</h1>
        <p>If you have any questions, please fill out the form below to send us a message.</p>
      </header>
    <form onSubmit={handleSubmit}>
       <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <label htmlFor="humanCheck">Please select "I am a human"</label>
        <select
          name="humanCheck"
          id="humanCheck"
          value={formData.humanCheck}
          onChange={handleChange}
          required
        >
          <option value="">--Please choose an option--</option>
          <option value="robot">I am a robot</option>
          <option value="human">I am a human</option>
          <option value="swan">I am a swan</option>
        </select>

        <button type="submit">Send Message</button>
      </form>
    </main>
  );
}