// src/pages/home.tsx

import HealthValues from "../components/healthvalues";
import MeetOurPractitioner from "../components/practitioner";
import Testimonials from "../components/testimonials";
import tranquilityImage from '../images/tranquility-bg.jpeg'; // Update with the correct path


export default function HomePage() {
  return (
    <main>
      <section className="welcome-section" style={{ backgroundImage: `url(${tranquilityImage})` }}>
        <h1>Tranquillity Spa - for health and beauty</h1>
        <p>Tranquillity Spa aims to help you relax and promote an improvement in general health and well-being, whilst rebalancing the body on a physical, mental and emotional level.</p>
      </section>
      <HealthValues />
      <Testimonials />
      <MeetOurPractitioner />
    </main>
  );
};

