import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import HomePage from './pages/home';
import PriceListPage from './pages/pricelistpage';
import { bodyContouring, specialCombinationPackages, treatments } from './data/pricelist';
import MassagePage from './pages/massagepage';
import ReikiPage from './pages/reikipage';
import ReflexologyPage from './pages/reflexologypage';
import BodyContouringPage from './pages/bodycontouringpage';
import ContactPage from './pages/contactpage';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
        <Header />
        <Routes>
          {/* Routes will go here */}
          <Route path="/" element={<HomePage />} />
          <Route path="/treatments" element={<PriceListPage prices={treatments} title='Our Treatments' />} />
          <Route path="/special-packages" element={<PriceListPage prices={specialCombinationPackages} title='Special Combination Packages' />} />
          <Route path="/body-contouring" element={<PriceListPage prices={bodyContouring} title='Body Contouring' footerText='Please note, 6 - 8 sessions are required for best results. Ideally weekly.' />} />
          <Route path='/massage' element={<MassagePage />} />
          <Route path='/reiki' element={<ReikiPage />} />
          <Route path='/reflexology' element={<ReflexologyPage/>} />
          <Route path='body-contouring-page' element={<BodyContouringPage/>} />
          <Route path='/contact' element={<ContactPage />} />
          {/* This is a catch-all route that will display '404 Not Found' for any unknown routes */}
          <Route path='*'>404 Not Found</Route> 
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
