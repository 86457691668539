import './footer.css'; // Make sure to create this CSS file
import welcome from '../images/welcome.png'; // Make sure to create this image
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section contact">
                <h3>CONTACT</h3>
                <p>Located near Adwick-Le-Street railway station (DN6)</p>
                <p>07814 674 993</p>
                <p>info@tranquillityspa.biz</p>
                <Link to="/contact">Contact Us</Link>
            </div>
            <div className="footer-section follow-us">
                <h3>FOLLOW US</h3>
                {/* Social media address is = https://www.facebook.com/TranquillitySpaforHealthandBeauty/  */}
                <a href="https://www.facebook.com/TranquillitySpaforHealthandBeauty/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </a>
            </div>
            <div className="footer-section welcome">
                <h3>EVERYONE IS WELCOME</h3>
                <img src={welcome} alt="Tranquillity Spa" />
            </div>
        </footer>
    );
};

export default Footer;
