import './massagepage.css'; // Make sure to create this CSS file
import massageRoomImage from '../images/massage-room.jpg'; // Update with the correct path
import massageTherapyImage from '../images/massage-therapy.jpg'; // Update with the correct path

const MassagePage = () => {
  return (
    <div className="massage-page-container">
      <div className="text-content">
        <h1>Therapeutic Massage</h1>
        <p>Massage can be used in many ways; it can be gentle, soothing and comforting, or it can be deep and invigorating; it can de-stress or it can help to rectify muscular-skeletal problems. Whatever the client's needs, massage can be tailored to help, both physically and emotionally.</p>
        <p>We often underestimate the power of touch, but as with most hands-on treatments, the client is aware of the therapeutic process as soon as the massage begins. The most obvious effect is one of warmth, closely followed by a sense of release in the muscles. If the client is under stress and needs a relaxing massage, the touch can be modified to suit the individual, but if the client has muscular-skeletal problems or over-worked muscles the massage can be deep and remedial.</p>
      </div>
      <div className="image-content">
        <img src={massageRoomImage} alt="Massage Room" />
        <img src={massageTherapyImage} alt="Massage Therapy" />
      </div>
    </div>
  );
};

export default MassagePage;
