export const treatments =  [
      {
        name: "Reiki",
        description: "1-hour - whole body treatment",
        price: 35,
      },
      {
        name: "Reflexology",
        description: "1 hour - can include hands, ears, and facial reflexology",
        price: 35,
      },
      {
        name: "Relaxing Fusion Massage",
        description: "1-hour – a combination of full body massage with reiki",
        price: 35,
      },
      {
        name: "Therapeutic Fusion Massage",
        description: "PERFECT FOR ANYONE SUFFERING WITH BACK/NECK/SHOULDER/HIPS/KNEES PROBLEMS -1.5 hours – a combination of full body massage with reiki",
        price: 45,
      },
      {
        name: "Exfoliating Feathersoft Facial",
        description: "40 minute treatment",
        price: 35,
      },
      {
        name: "Luxurious Ultrasonic Facial",
        description: "1.5 hour treatment",
        price: 55,
      },
      {
        name: "Hopi Ear Candles",
        description: "includes synovial massage – 1 hour",
        price: 35,
      },
      {
        name: "Sports Massage",
        description: "1 hour treatment",
        price: 45,
      },
      {
        name: "Hot Stone Massage",
        description: "1.5 hours – a combination of full body massage using hot stones, with reiki (Available as part of a combination package)",
        price: 45,
      }
    ]
  
    export const specialCombinationPackages = [
        {
          name: "Reflexology and Relaxing Massage",
          description: "1.75 hours",
          price: 50
        },
        {
          name: "Reflexology and Therapeutic Massage",
          description: "2 hours",
          price: 60
        },
        {
          name: "Reflexology and Relaxing Massage and Feathersoft Facial",
          description: "2.5 hours",
          price: 75
        },
        {
          name: "Reflexology and Relaxing Massage and Ultrasonic Facial",
          description: "3 hours",
          price: 95
        },
        {
          name: "Reflexology and Therapeutic Massage and Feathersoft Facial",
          description: "2.5 hours",
          price: 85
        },
        {
          name: "Reflexology and Therapeutic Massage and Ultrasonic Facial",
          description: "3.5 hours",
          price: 105
        },
        {
          name: "Relaxing Massage and Feathersoft Facial",
          description: "1.75 hours",
          price: 55
        },
        {
          name: "Therapeutic Massage and Feathersoft Facial",
          description: "2 hours",
          price: 65
        },
        {
          name: "Relaxing Massage and Ultrasonic Facial",
          description: "2.5 hours",
          price: 75
        },
        {
          name: "Therapeutic Massage and Ultrasonic Facial",
          description: "3 hours",
          price: 85
        },
        {
          name: "Hopi Ear Candles as an add-on to any package",
          description: "1 hour",
          price: 20
        }
      ];
      
 export const bodyContouring = [
    {
      name: "Chiseled Jawline",
      description: "Using laser lipolysis and radio frequency to help reduce fat and define the jawline - 1 hour",
      price: 60
    },
    {
      name: "Radio Frequency Facial",
      description: "To define jawline and encourage collagen production. Firms, lifts and rejuvenates the face - 1 hour session",
      price: 80
    },
    {
      name: "Ultrasonic Cavitation or Radio Frequency or Laser Lipolysis",
      description: "1 hour session (1 area per treatment i.e waist or thighs)",
      price: 60
    },
    {
      name: "Cavitation plus Radio Frequency and Vacuum Therapy",
      description: "A 1.5 hours session",
      price: 80
    },
    {
      name: "Body Contouring/Slim Waist combination treatment",
      description: "To achieve the best inch loss results - 2 hour session to include Laser Lipolysis, Ultrasonic Cavitation, Radio Frequency, and Vacuum Therapy",
      price: 100
    }
  ];
      